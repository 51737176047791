import TableCustom from "@/components/TableCustom";
import { productColumns } from "../../warehouseCUR.constants";
import { checkTotalAllItem, getError } from "@/utils/helpers";
import { useDeleteProductMutation, useGetProductListQuery, useRestoreProductMutation } from "@/rtk-query/productQueries";
import { Pagination, Row, Typography, notification } from "antd";
import { useState } from "react";
import C from "@/utils/constants";
import styles from "../../warehouseCUR.module.less";
import { useHistory, useLocation } from "react-router";
import routes from "@/routes";
import { Toast } from "@/app/store";
import message from "@/utils/message";

interface IProductListProps {
  // eslint-disable-next-line
  binId?: string | number;
}

// eslint-disable-next-line
const ProductList = ({}: IProductListProps) => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const binId = query.get("binId");

  const [loadingList, setLoadingList] = useState<Array<string | number>>([]);
  const [page, setPage] = useState(1);

  const { currentData } = useGetProductListQuery({
    page,
    pageSize: C.PAGE_SIZE_LITTLE,
    binId: binId || "",
    isLoading: undefined,
    position: "",
  });
  const [deleteProductApi] = useDeleteProductMutation();
  const [restoreProductApi] = useRestoreProductMutation();

  const handleLoaded = (id: string | number) => {
    setTimeout(() => {
      setLoadingList(loadingList.filter((item) => item !== id));
    }, 300);
  };

  const handleDeleteRestore = async (id: string | number, type?: "delete" | "restore") => {
    setLoadingList([...loadingList, id]);
    Toast.close();

    try {
      type === "delete" ? await deleteProductApi(id).unwrap() : await restoreProductApi(id).unwrap();
      notification.success({
        message: message.SUCCESSFULLY,
      });
      handleLoaded(id);
    } catch (error) {
      notification.error({
        key: "error_product_del",
        message: getError(error),
      });
      handleLoaded(id);
    }
  };

  const handleDeletePopup = (id: string | number) => {
    Toast.delete({
      isOpen: true,
      title: "Are you sure you want to delete this product?",
      description: "You will not able to view and edit this product’s information.",
      cancelText: "Cancel",
      okText: "Delete",
      onCancel: () => {
        Toast.close();
      },
      onOk: () => handleDeleteRestore(id, "delete"),
      okStyle: {
        background: "#F32121",
      },
    });
  };

  return (
    <div className={styles.listProductWrapper}>
      <div className={styles.listProductHeader}>
        <Typography.Title
          level={5}
          style={{
            color: "#234C66",
            margin: 0,
          }}
        >
          LIST OF PRODUCTS
        </Typography.Title>
      </div>
      <TableCustom
        columns={productColumns({
          onEdit: (id: string) => {
            history.push(routes.editSiteProduct.path.replace(":id", id));
          },
          onDelete: (id: string | number) => {
            handleDeletePopup(id);
          },
          onRestore: (id: string | number) => handleDeleteRestore(id, "restore"),
        })}
        data={currentData?.data || []}
        loading={false}
        total={checkTotalAllItem({ page: currentData?.page || 1 })}
      />
      {(currentData?.totalPage || 0) > 1 && (
        <Row
          justify="end"
          style={{
            marginTop: 24,
          }}
        >
          <Pagination
            current={page}
            total={currentData?.totalPage || 0}
            pageSize={1}
            onChange={(page) => {
              setPage(page);
            }}
            showSizeChanger={false}
          />
        </Row>
      )}
    </div>
  );
};

export default ProductList;
